export default {
  area: "安洛云综合服务平台",
  objectName: "安洛云综合服务平台",
  point: { lng: 112.361409, lat: 34.660613 },
  mapAttr: {
    mapType: "BMAP_NORMAL_MAP",
    zoom: 17
  },
  //url
  hostUrl: 'https://app.anluoyun.cn/',
  apiHttpsUrl: 'https://api.anluoyun.cn/',
  apiRequestUrl: 'https://api.anluoyun.cn/api',
  apiUploadUrl: 'https://api.anluoyun.cn/api/UploadFile/UploadImgs',
  apiUploadFiles: 'https://api.anluoyun.cn/api/UploadFile/UploadFiles',
  apiUploadFilesMaterial: 'https://api.anluoyun.cn/api/Material/AddMediaMate',
  // logo: require("../../src/assets/logo.png"),
  CopyRight: "Copyright @2020-2025 河南安洛云信息科技有限公司  技术服务：400 6379 003",
}