import request from '@/utils/request'
// 获取工单列表
export function GetMyWorkOrderPage (query) {
  return request({
    url: 'WorkOrder/GetMyWorkOrderPage',
    method: 'get',
    params: query
  })
}
// 获取工单详情
export function GetWorkOrder (query) {
  return request({
    url: 'WorkOrder/GetWorkOrder',
    method: 'get',
    params: query
  })
}
// 获取系统列表
export function GetSysSoftPage (query) {
  return request({
    url: 'SysSoft/GetSysSoftPage',
    method: 'get',
    params: query
  })
}
// 获取用户列表
export function GetUserPage (query) {
  return request({
    url: 'Dept/GetUserPage',
    method: 'get',
    params: query
  })
}
export function GetMyCollecAreaList (query) {
  return request({
    url: 'IArea/GetMyCollecAreaList',
    method: 'get',
    params: query
  })
}
// 保存工单
export function SaveWorkOrder (data) {
  return request({
    url: 'WorkOrder/SaveWorkOrder',
    method: 'post',
    data: data
  })
}
// 提报工单
export function SubmitWorkOrder (data) {
  return request({
    url: 'WorkOrder/SubmitWorkOrder',
    method: 'post',
    data: data
  })
}
// 接收工单
export function RecWorkOrder (data) {
  return request({
    url: 'WorkOrder/RecWorkOrder',
    method: 'post',
    data: data
  })
}
// 处理工单
export function DealWorkOrder (data) {
  return request({
    url: 'WorkOrder/DealWorkOrder',
    method: 'post',
    data: data
  })
}
// 验收工单
export function CheckWorkOrder (data) {
  return request({
    url: 'WorkOrder/CheckWorkOrder',
    method: 'post',
    data: data
  })
}
// 终止工单
export function StopWorkOrder (data) {
  return request({
    url: 'WorkOrder/StopWorkOrder',
    method: 'post',
    data: data
  })
}

// 删除工单
export function DeleteWorkOrder (data) {
  return request({
    url: 'WorkOrder/DeleteWorkOrder',
    method: 'post',
    data: data
  })
}